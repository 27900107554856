import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { db } from "../../../../Firebase";
import { doc, onSnapshot } from "firebase/firestore";
import ListsOverview from './parts/ListsOverview';
import ListsTable from './parts/ListsTable';

const ListsAPI = ({ workspaceId, groupId, listId }) => {
  const [rawData, setRawData] = useState(null);
  const [creatorData, setCreatorData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let unsubscribe;

    const fetchRawData = () => {
      if (!workspaceId || !groupId || !listId) return;

      const listDocRef = doc(
        db,
        "workspaces",
        workspaceId,
        "groups",
        groupId,
        "lists",
        listId
      );

      unsubscribe = onSnapshot(
        listDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            setRawData(docSnapshot.data());
          } else {
            setError("List not found");
          }
          setIsLoading(false);
        },
        (error) => {
          console.error("Error listening to list document:", error);
          setError("Failed to fetch list data");
          setIsLoading(false);
        }
      );
    };

    fetchRawData();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [workspaceId, groupId, listId]);

  useEffect(() => {
    const fetchCreatorData = async () => {
      if (!rawData || !rawData.creators || rawData.creators.length === 0) {
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const creatorIds = rawData.creators.map((creator) => creator.creatorId);
        const response = await fetch(
          "https://hoobe-server-v5.onrender.com/lists-full",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ creatorIds }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to send creators to the API: ${response.statusText}`
          );
        }

        const responseData = await response.json();
        setCreatorData(responseData);
      } catch (error) {
        console.error("Error in fetchCreatorData:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCreatorData();
  }, [rawData]);

  const isListEmpty = !isLoading && !error && rawData && (!rawData.creators || rawData.creators.length === 0);

  if (error) {
    return (
      <Flex justifyContent="center" alignItems="center" height="80vh">
        <Text color="red.500">{error}</Text>
      </Flex>
    );
  }

  if (isListEmpty) {
    return (
      <VStack justifyContent="center" alignItems="center" height="80vh">
        <Heading fontSize="2xl">Uh Oh!</Heading>
        <Text fontSize="xl">Your list is empty, please add creators!</Text>
      </VStack>
    );
  }

  return (

      <Box>
        <ListsOverview rawData={rawData} creatorData={creatorData} />
        <ListsTable
          rawData={rawData}
          creatorData={creatorData}
          workspaceId={workspaceId}
          groupId={groupId}
          listId={listId}
        />
      </Box>
  );
};

export default React.memo(ListsAPI);