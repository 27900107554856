import React, { useState, useEffect, useRef } from 'react';
import { Button, Icon, Box, Checkbox, VStack } from "@chakra-ui/react";
import { TbColumns2 } from 'react-icons/tb';
import { motion, AnimatePresence } from 'framer-motion';

const ColumnDropdown = ({ isOpen, onClose, localVisibleColumns, handleCheckboxChange }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.2 }}
        style={{
          position: 'absolute',
          top: '60px',
          right: 0,
          zIndex: 10000,
          width: '300px',
        }}
      >
        <Box
          p={4}
          bg="white"
          borderRadius="xl"
          borderWidth="1px"
          boxShadow="md"
        >
          <VStack align="start" spacing={3}>
            {Object.entries(localVisibleColumns).map(([column, isChecked]) => (
              <Checkbox
                key={column}
                isChecked={isChecked}
                onChange={() => handleCheckboxChange(column)}
              >
                {column.charAt(0).toUpperCase() + column.slice(1).replace(/([A-Z])/g, ' $1').trim()}
              </Checkbox>
            ))}
          </VStack>
        </Box>
      </motion.div>
    )}
  </AnimatePresence>
);

const Column = ({ setVisibleColumns }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localVisibleColumns, setLocalVisibleColumns] = useState({
    creator: true,
    followers: true,
    category: true,
    engagementRate: true,
    IGReelsImpressions: true,
    IGStoriesImpressions: false,
    IGPostImpressions: false,
    tiktokPostImpressions: true,
  });

  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleCheckboxChange = (column) => {
    setLocalVisibleColumns((prev) => {
      const updated = { ...prev, [column]: !prev[column] };
      setVisibleColumns(updated);
      return updated;
    });
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  return (
    <Box position="relative" ref={dropdownRef}>
      <Button
        ref={buttonRef}
        size="lg"
        color="white"
        bg="gray.800"
        leftIcon={<Icon as={TbColumns2} />}
        _hover={{ bg: "black" }}
        borderRadius="xl"
        onClick={toggleDropdown}
      >
        Columns
      </Button>
      <ColumnDropdown
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        localVisibleColumns={localVisibleColumns}
        handleCheckboxChange={handleCheckboxChange}
      />
    </Box>
  );
};

export default Column;