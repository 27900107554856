// Dashboard.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  VStack,
  Button,
  Spinner,
  Flex,
  Image,
  Text,
  Avatar,
  Collapse,
  IconButton,
} from "@chakra-ui/react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  onSnapshot,
  orderBy,
  writeBatch,
} from "firebase/firestore";
import logo from "../assets/logo.png";
import {
  TbSearch,
  TbMail,
  TbSpeakerphone,
  TbClipboardText,
  // TbDatabase,
  TbChevronDown,
  TbChevronRight,
} from "react-icons/tb";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const menuItems = [
  // { name: "Data", icon: TbDatabase, path: "/dashboard/data" },
  { name: "Search", icon: TbSearch, path: "/dashboard/search" },
  { name: "Messages", icon: TbMail, path: "/dashboard/messages" },
  { name: "Campaigns", icon: TbSpeakerphone, path: "/dashboard/campaigns" },
  { name: "Lists", icon: TbClipboardText, path: "/dashboard/lists" },
];

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [workspaceData, setWorkspaceData] = useState(null);
  const [groups, setGroups] = useState([]);

  // Use an array to track multiple expanded groups
  const [expandedGroupIds, setExpandedGroupIds] = useState([]);

  const [isListsExpanded, setIsListsExpanded] = useState(false);
  const navigate = useNavigate();

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) return navigate("/login");

      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", currentUser.email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) return;

      const userDoc = querySnapshot.docs[0];
      const userDocRef = doc(db, "users", userDoc.id);

      const unsubscribeUser = onSnapshot(userDocRef, (docSnap) => {
        setUserData(docSnap.data());
      });

      const workspaceId = userDoc.data().workspaceId;
      if (workspaceId) {
        const workspaceDocRef = doc(db, "workspaces", workspaceId);
        const unsubscribeWorkspace = onSnapshot(workspaceDocRef, (docSnap) => {
          setWorkspaceData(docSnap.data());
        });

        const groupsRef = collection(db, "workspaces", workspaceId, "groups");
        const groupsQuery = query(groupsRef, orderBy("order"));
        const unsubscribeGroups = onSnapshot(groupsQuery, async (snapshot) => {
          const groupsData = await Promise.all(
            snapshot.docs.map(async (docSnap) => {
              const groupData = { id: docSnap.id, ...docSnap.data() };

              const listsRef = collection(
                db,
                "workspaces",
                workspaceId,
                "groups",
                docSnap.id,
                "lists"
              );
              const listsQuery = query(listsRef, orderBy("order"));
              const listsSnapshot = await getDocs(listsQuery);
              const listsData = listsSnapshot.docs.map((listDoc) => ({
                id: listDoc.id,
                ...listDoc.data(),
              }));

              return { ...groupData, lists: listsData };
            })
          );
          setGroups(groupsData);
        });

        return () => {
          unsubscribeUser();
          unsubscribeWorkspace();
          unsubscribeGroups();
        };
      }
    });

    return () => unsubscribeAuth();
  }, [navigate]);

  const handleGroupDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id) {
      const oldIndex = groups.findIndex((item) => item.id === active.id);
      const newIndex = groups.findIndex((item) => item.id === over.id);
      const newGroups = arrayMove(groups, oldIndex, newIndex);
      setGroups(newGroups);

      const batch = writeBatch(db);
      newGroups.forEach((group, index) => {
        const groupRef = doc(
          db,
          "workspaces",
          workspaceData.workspace_id,
          "groups",
          group.id
        );
        batch.update(groupRef, { order: index });
      });
      await batch.commit();
    }
  };

  const handleListDragEnd = async ({ active, over }, groupId) => {
    if (active.id !== over?.id) {
      const groupIndex = groups.findIndex((group) => group.id === groupId);
      const group = groups[groupIndex];
      const oldIndex = group.lists.findIndex((item) => item.id === active.id);
      const newIndex = group.lists.findIndex((item) => item.id === over.id);
      const newLists = arrayMove(group.lists, oldIndex, newIndex);

      const newGroups = [...groups];
      newGroups[groupIndex] = { ...group, lists: newLists };
      setGroups(newGroups);

      const batch = writeBatch(db);
      newLists.forEach((list, index) => {
        const listRef = doc(
          db,
          "workspaces",
          workspaceData.workspace_id,
          "groups",
          groupId,
          "lists",
          list.id
        );
        batch.update(listRef, { order: index });
      });
      await batch.commit();
    }
  };

  if (!userData || !workspaceData) {
    return (
      <Flex height="100vh" width="100vw" alignItems="center" justifyContent="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex height="100vh">
      <Box
        width="250px"
        borderRight="2px"
        borderColor="gray.200"
        display="flex"
        flexDirection="column"
      >
        <Flex
          width="250px"
          height="60px"
          borderBottom="2px"
          borderColor="gray.200"
          p={4}
          alignItems="center"
        >
          <Image src={logo} alt="Logo" boxSize="25px" mr={2} />
          <Heading size="sm" noOfLines={1}>
            {workspaceData.workspace_name?.length > 18
              ? workspaceData.workspace_name.substring(0, 18) + "..."
              : workspaceData.workspace_name}
          </Heading>
        </Flex>

        <VStack spacing="2px" align="stretch" p={2} flex="1" overflowY="auto">
          {menuItems.map((item) => {
            if (item.name === "Lists") {
              return (
                <Box key={item.name}>
                  <Flex
                    align="center"
                    justify="space-between"
                    _hover={{ bg: "gray.100" }}
                    borderRadius="md"
                    transition="background-color 0.3s"
                  >
                    <Button
                      as={Link}
                      to={item.path}
                      leftIcon={<item.icon size="18px" />}
                      variant="ghost"
                      justifyContent="flex-start"
                      size="sm"
                      flex="1"
                    >
                      {item.name}
                    </Button>
                    <IconButton
                      aria-label="Toggle Lists"
                      icon={isListsExpanded ? <TbChevronDown /> : <TbChevronRight />}
                      size="sm"
                      variant="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsListsExpanded(!isListsExpanded);
                      }}
                    />
                  </Flex>

                  <Collapse in={isListsExpanded} animateOpacity>
                    <Box mt={1}>
                      <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleGroupDragEnd}
                      >
                        <SortableContext
                          items={groups.map((group) => group.id)}
                          strategy={verticalListSortingStrategy}
                        >
                          {groups.map((group) => (
                            <Box key={group.id}>
                              <SortableGroupItem
                                id={group.id}
                                group={group}
                                navigate={navigate}
                                isExpanded={expandedGroupIds.includes(group.id)}
                                toggleExpand={() => {
                                  setExpandedGroupIds((prev) =>
                                    prev.includes(group.id)
                                      ? prev.filter((id) => id !== group.id)
                                      : [...prev, group.id]
                                  );
                                }}
                              />
                              <Collapse
                                in={expandedGroupIds.includes(group.id)}
                                animateOpacity
                              >
                                <Box pl={2}>
                                  <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={(event) => handleListDragEnd(event, group.id)}
                                  >
                                    <SortableContext
                                      items={group.lists.map((list) => list.id)}
                                      strategy={verticalListSortingStrategy}
                                    >
                                      {group.lists.map((list) => (
                                        <SortableListItem
                                          key={list.id}
                                          id={list.id}
                                          list={list}
                                          navigate={navigate}
                                          groupId={group.id}
                                        />
                                      ))}
                                    </SortableContext>
                                  </DndContext>
                                </Box>
                              </Collapse>
                            </Box>
                          ))}
                        </SortableContext>
                      </DndContext>
                    </Box>
                  </Collapse>
                </Box>
              );
            } else {
              return (
                <Button
                  key={item.name}
                  as={Link}
                  to={item.path}
                  leftIcon={<item.icon size="18px" />}
                  variant="ghost"
                  justifyContent="flex-start"
                  size="sm"
                  width="100%"
                >
                  {item.name}
                </Button>
              );
            }
          })}
        </VStack>

        <Flex
          as={Link}
          to="/dashboard/settings"
          height="80px"
          borderTop="2px"
          borderColor="gray.200"
          p={4}
          alignItems="center"
          cursor="pointer"
          _hover={{ bg: "gray.100" }}
          transition="background-color 0.2s"
        >
          <Avatar
            size="sm"
            bg="gray.300"
            color="#222222"
            name={userData.fullName}
            mr={3}
          />
          <Box>
            <Text fontWeight="bold" fontSize="sm">
              {userData.fullName}
            </Text>
            <Text fontSize="xs" color="gray.500">
              {userData.userId === workspaceData.owner ? "Admin" : "User"}
            </Text>
          </Box>
        </Flex>
      </Box>

      <Box flex="1">
        <Outlet context={{ userData, workspaceData }} />
      </Box>
    </Flex>
  );
};

const SortableGroupItem = ({ id, group, navigate, isExpanded, toggleExpand }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    cursor: isDragging ? "grabbing" : "grab",
    userSelect: "none",
    transition: "background-color 0.3s"
  };

  const handleClick = () => {
    navigate(`/dashboard/lists/${group.id}`);
  };

  return (
    <Flex
      align="center"
      ref={setNodeRef}
      style={style}
      width="100%"
      _hover={{ bg: "gray.100" }}
      borderRadius="md"
      mb="2px"
    >
      <Button
        variant="ghost"
        justifyContent="flex-start"
        size="sm"
        flex="1"
        onClick={handleClick}
        {...attributes}
        {...listeners}
      >
        {group.name}
      </Button>
      <IconButton
        aria-label="Toggle Group"
        icon={isExpanded ? <TbChevronDown /> : <TbChevronRight />}
        size="sm"
        variant="ghost"
        onClick={(e) => {
          e.stopPropagation();
          toggleExpand();
        }}
      />
    </Flex>
  );
};

const SortableListItem = ({ id, list, navigate, groupId }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    cursor: isDragging ? "grabbing" : "grab",
    userSelect: "none",
  };

  const handleClick = () => {
    navigate(`/dashboard/lists/${groupId}/${list.id}`);
  };

  return (
    <Flex
      align="center"
      ref={setNodeRef}
      style={style}
      width="100%"
      mb="2px"
    >
      <Button
        variant="ghost"
        justifyContent="flex-start"
        size="sm"
        flex="1"
        onClick={handleClick}
        {...attributes}
        {...listeners}
        pl={4}
      >
        {list.name}
      </Button>
    </Flex>
  );
};

export default Dashboard;
