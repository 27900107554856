import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
  Text,
  Box,
  Badge,
  VStack,
  HStack,
  Grid,
  GridItem,
  Button,
  Link,
  ModalFooter,
  Input,
  Flex,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { TbHeart, TbMessageCircle, TbShare, TbEye } from "react-icons/tb";
import instagramIcon from "../../../../../assets/instagrampost.png";
import instagramReel from "../../../../../assets/instagramreel.png";
import instagramStory from "../../../../../assets/instagramstory.png";
import tiktokVideo from "../../../../../assets/tiktok.png";
import verifiedBadge from "../../../../../assets/verifiedbadge.png";
import placeholderImage from "../../../../../assets/placeholder.jpg";
import { FormatNumber } from "../../../formatting/FormatNumber";

const CampaignTrackingModal = ({
  isOpen,
  onClose,
  post,
  index,
  onDelete,
  onUpdate,
}) => {
  const [editingField, setEditingField] = useState(null);
  const [fieldValue, setFieldValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (editingField && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingField]);

  if (!post) return null;

  const getPostIcon = (platform, type) => {
    if (platform === "instagram") {
      if (type === "reel") return instagramReel;
      if (type === "story") return instagramStory;
      return instagramIcon;
    }
    if (platform === "tiktok") return tiktokVideo;
    return null;
  };

  const getContentTypeText = (platform, type) => {
    if (platform === "instagram") {
      if (type === "reel") return "Instagram Reel";
      if (type === "story") return "Instagram Story";
      return "Instagram Post";
    }
    if (platform === "tiktok") return "TikTok Video";
    return "Unknown Content Type";
  };

  const handleFieldClick = (field) => {
    setEditingField(field);
    setFieldValue(post[field].toString());
  };

  const handleInputChange = (e) => {
    setFieldValue(e.target.value);
  };

  const handleSave = () => {
    const numericValue = parseInt(fieldValue, 10);
    if (!isNaN(numericValue) && numericValue >= 0) {
      onUpdate(index, editingField, numericValue);
    }
    setEditingField(null);
    setFieldValue("");
  };

  const handleInputBlur = () => {
    handleSave();
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave();
    } else if (e.key === "Escape") {
      setEditingField(null);
      setFieldValue("");
    }
  };

  const stats = [
    { field: "likes", label: "Likes", icon: TbHeart },
    { field: "comments", label: "Comments", icon: TbMessageCircle },
    { field: "shares", label: "Shares", icon: TbShare },
    { field: "impressions", label: "Impressions", icon: TbEye },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setEditingField(null);
        onClose();
      }}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Creator Post</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="1fr 2fr" gap={6}>
            <GridItem>
              <Box w="100%" position="relative">
                <Link href={post.content_url} isExternal>
                  <Image
                    src={post.thumbnail_url || placeholderImage}
                    alt="Post thumbnail"
                    borderRadius="20px"
                    w="100%"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = placeholderImage;
                    }}
                  />
                </Link>
              </Box>
            </GridItem>

            <GridItem>
              <VStack align="start" spacing={4}>
                <HStack spacing={4}>
                  <Box position="relative" w="60px" h="60px">
                    <Image
                      boxSize="60px"
                      borderRadius="full"
                      src={post.profile_pic_url}
                      alt={`${post.username}'s profile picture`}
                      objectFit="cover"
                    />
                    {post.is_verified && (
                      <Image
                        src={verifiedBadge}
                        alt="Verified Badge"
                        boxSize="20px"
                        position="absolute"
                        top="0"
                        left="0"
                      />
                    )}
                  </Box>
                  <Box>
                    <Text fontWeight="bold" fontSize="lg">
                      {post.name}
                    </Text>
                    <Text fontSize="lg">@{post.username}</Text>
                  </Box>
                </HStack>

                {post.caption && (
                  <Box>
                    <Text fontWeight="bold">Caption:</Text>
                    <Box
                      overflowY="auto"
                      css={{
                        "&::-webkit-scrollbar": {
                          width: "4px",
                        },
                        "&::-webkit-scrollbar-track": {
                          width: "6px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "gray.300",
                          borderRadius: "24px",
                        },
                      }}
                    >
                      <Text>{post.caption}</Text>
                    </Box>
                  </Box>
                )}

                <HStack spacing={4} align="center">
                  <HStack borderWidth="1px" p={2} borderRadius="md" spacing={2}>
                    <Image
                      src={getPostIcon(post.platform, post.type)}
                      alt={`${post.platform} ${post.type} icon`}
                      width="24px"
                      height="24px"
                    />
                    <Text fontSize="sm" fontWeight="bold">
                      {getContentTypeText(post.platform, post.type)}
                    </Text>
                  </HStack>

                  <Link href={post.url} isExternal>
                    <Button borderWidth="1px" bg="white" rightIcon={<ExternalLinkIcon />}>
                      View Original Post
                    </Button>
                  </Link>
                </HStack>

                <Grid templateColumns="repeat(2, 1fr)" gap={2} w="100%">
                  {stats.map((stat) => {
                    const IconComponent = stat.icon;
                    return (
                      <GridItem key={stat.field}>
                        <Box
                          px={4}
                          py={2}
                          borderWidth="1px"
                          borderRadius="md"
                          borderColor="gray.200"
                        >
                          <Flex>
                            <Box as={IconComponent} size="24px" color="gray.600" mr={2} />
                            <Box>
                              <Text fontSize="sm" color="gray.600">
                                {stat.label}
                              </Text>
                              {editingField === stat.field ? (
                                <Input
                                  ref={inputRef}
                                  value={fieldValue}
                                  onChange={handleInputChange}
                                  onBlur={handleInputBlur}
                                  onKeyDown={handleInputKeyDown}
                                  size="sm"
                                  borderRadius="xl"
                                  mt={1}
                                />
                              ) : (
                                <Text
                                  cursor="pointer"
                                  onClick={() => handleFieldClick(stat.field)}
                                  fontSize="2xl"
                                  fontWeight="semibold"
                                >
                                  {stat.field === "impressions"
                                    ? FormatNumber(post[stat.field])
                                    : post[stat.field]}
                                </Text>
                              )}
                            </Box>
                          </Flex>
                        </Box>
                      </GridItem>
                    );
                  })}
                </Grid>

                <Box>
                  <Text fontWeight="bold">Created At:</Text>
                  <Text>{new Date(post.createdAt).toLocaleString()}</Text>
                </Box>

                {post.taggedUsers && post.taggedUsers.length > 0 && (
                  <Box>
                    <Text fontWeight="bold">Tagged Users:</Text>
                    <VStack align="start" spacing={1}>
                      {post.taggedUsers.map((user, index) => (
                        <HStack key={index} spacing={2}>
                          <Text>@{user.username}</Text>
                          {user.is_verified && (
                            <Badge colorScheme="blue" ml="1">
                              Verified
                            </Badge>
                          )}
                        </HStack>
                      ))}
                    </VStack>
                  </Box>
                )}
              </VStack>
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={() => onDelete()}>
            Delete Post
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CampaignTrackingModal;
