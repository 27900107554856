import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Input,
  Button,
  Flex,
  Spinner,
  Image,
  Text,
  Fade,
} from "@chakra-ui/react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { db } from "../../../Firebase";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  doc,
  writeBatch,
} from "firebase/firestore";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import placeholderImage from "../../../assets/placeholder.jpg";
import { TbPlus } from "react-icons/tb";

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState(null);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  const { workspaceData } = useOutletContext();

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );

  useEffect(() => {
    if (!workspaceData?.workspace_id) return;

    const q = query(
      collection(db, "workspaces", workspaceData.workspace_id, "campaigns"),
      orderBy("order")
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) =>
        setCampaigns(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))),
      (error) => {
        console.error("Error fetching campaigns:", error);
        setCampaigns([]);
      }
    );

    return unsubscribe;
  }, [workspaceData]);

  const handleDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id && campaigns) {
      const oldIndex = campaigns.findIndex((c) => c.id === active.id);
      const newIndex = campaigns.findIndex((c) => c.id === over.id);
      const reordered = arrayMove(campaigns, oldIndex, newIndex);
      setCampaigns(reordered);

      const batch = writeBatch(db);
      reordered.forEach((campaign, index) => {
        batch.update(
          doc(db, "workspaces", workspaceData.workspace_id, "campaigns", campaign.id),
          { order: index }
        );
      });
      try {
        await batch.commit();
      } catch (error) {
        console.error("Error updating campaign order:", error);
      }
    }
  };

  const filteredCampaigns = campaigns?.filter((c) =>
    c.name.toLowerCase().includes(filterText.toLowerCase())
  );

  if (!workspaceData?.workspace_id)
    return <Box p={4}>Workspace data is unavailable.</Box>;

  return (
    <Box>
      <Flex
        height="60px"
        alignItems="center"
        borderBottomWidth="2px"
        p={4}
        justifyContent="space-between"
      >
        <Heading size="md">Campaigns</Heading>
        <Flex align="center">
          <Input
            placeholder="Search campaign..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            size="sm"
            borderRadius="md"
            w="300px"
            mr={2}
          />
          <Button
            size="sm"
            colorScheme="gray"
            borderWidth="1px"
            bg="white"
            leftIcon={<TbPlus />}
            onClick={() => navigate("/dashboard/campaigns/new")}
          >
            Add Campaign
          </Button>
        </Flex>
      </Flex>

      {campaigns === null ? (
        <Flex justify="center" align="center" height="200px">
          <Spinner size="lg" />
        </Flex>
      ) : filteredCampaigns?.length ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={filteredCampaigns.map((c) => c.id)}
            strategy={rectSortingStrategy}
          >
            <Flex wrap="wrap" px={2}>
              {filteredCampaigns.map((campaign) => (
                <Fade
                  key={campaign.id}
                  in={true}
                  transition={{ enter: { duration: 0.5, delay: 0.1 } }}
                >
                  <SortableItem
                    id={campaign.id}
                    name={campaign.name}
                    navigate={navigate}
                    description={campaign.description}
                    imageURL={campaign.imageURL}
                  />
                </Fade>
              ))}
            </Flex>
          </SortableContext>
        </DndContext>
      ) : (
        <Box p={4}>No campaigns available.</Box>
      )}
    </Box>
  );
};

const SortableItem = ({ id, name, navigate, description, imageURL }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    cursor: isDragging ? "grabbing" : "grab",
  };

  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      p={3}
      m={2}
      borderWidth="1px"
      borderRadius="xl"
      minW="300px"
      bg="white"
      boxShadow="sm"
      onClick={() => navigate(`/dashboard/campaigns/${id}`)}
      _hover={{ bg: "gray.100" }}
    >
      <Flex>
        <Fade in={imageLoaded} transition={{ enter: { duration: 0.3 } }}>
          <Image
            src={imageURL || placeholderImage}
            alt={`${name} Image`}
            boxSize="80px"
            objectFit="cover"
            borderRadius="md"
            mr={4}
            onLoad={() => setImageLoaded(true)}
          />
        </Fade>
        <Box>
          <Heading size="sm" mb={2}>
            {name || "none"}
          </Heading>
          <Text fontSize="xs" color="gray.500">
            {description || "none"}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Campaigns;
