import React, { useState, useEffect } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  VStack,
  Heading,
  Textarea,
  Text,
  Flex,
  Image,
  Spinner,
  HStack,
  Wrap,
  useToast,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  Tooltip,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { updateDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../Firebase";
import InstagramPostIcon from "../../../assets/instagrampost.png";
import InstagramReelIcon from "../../../assets/instagramreel.png";
import InstagramStoryIcon from "../../../assets/instagramstory.png";
import TikTokIcon from "../../../assets/tiktok.png";

const CampaignsAddMedia = ({ isOpen, onClose, workspaceId, campaignId }) => {
  const [inputText, setInputText] = useState("");
  const [mediaItems, setMediaItems] = useState([]);
  const [isLoadingAddMedia, setIsLoadingAddMedia] = useState(false);
  const [isLoadingSearchCreators, setIsLoadingSearchCreators] = useState(false);
  const [listeningKeywords, setListeningKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState("");
  const toast = useToast();

  const MEDIA_TYPES = {
    INSTAGRAM_POST: "Instagram Post",
    INSTAGRAM_REEL: "Instagram Reel",
    INSTAGRAM_STORY: "Instagram Story",
    TIKTOK_VIDEO: "TikTok Video",
  };

  const MEDIA_ICONS = {
    [MEDIA_TYPES.INSTAGRAM_POST]: InstagramPostIcon,
    [MEDIA_TYPES.INSTAGRAM_REEL]: InstagramReelIcon,
    [MEDIA_TYPES.INSTAGRAM_STORY]: InstagramStoryIcon,
    [MEDIA_TYPES.TIKTOK_VIDEO]: TikTokIcon,
  };

  const regexPatterns = {
    [MEDIA_TYPES.INSTAGRAM_POST]:
      /(https?:\/\/)?(www\.)?instagram\.com\/p\/[A-Za-z0-9_-]+/g,
    [MEDIA_TYPES.INSTAGRAM_REEL]:
      /(https?:\/\/)?(www\.)?instagram\.com\/reel(s)?\/[A-Za-z0-9_-]+\/?/g,
    [MEDIA_TYPES.INSTAGRAM_STORY]:
      /(https?:\/\/)?(www\.)?instagram\.com\/stories\/[^/]+(?:\/\d+)?\/?/g,
    [MEDIA_TYPES.TIKTOK_VIDEO]:
      /(https?:\/\/)?(www\.)?tiktok\.com\/@[^/]+\/video\/\d+/g,
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputText(text);
    const foundMedia = Object.keys(regexPatterns).flatMap((type) => {
      const matches = text.match(regexPatterns[type]);
      return matches ? matches.map((url) => ({ type, url })) : [];
    });
    setMediaItems(foundMedia);
  };

  const handleAddMedia = async () => {
    setIsLoadingAddMedia(true);
    try {
      const mediaURLs = mediaItems.map((item) => item.url);
      const baseUrl = process.env.REACT_APP_HOOBE_API_URL;
      const response = await fetch(`${baseUrl}/campaigns-add-media-new`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          workspaceId,
          campaignId,
          urls: mediaURLs,
          listeningKeywords,
        }),
      });
      if (response.ok) {
        toast({
          title: "Media Added Successfully!",
          description: "The media items have been added to your campaign.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        onClose();
      } else {
        const errorData = await response.json();
        toast({
          title: "Failed to Add Media.",
          description: errorData.message || "An unexpected error occurred.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "An Error Occurred.",
        description:
          "Unable to add media at this time. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    } finally {
      setIsLoadingAddMedia(false);
    }
  };

  const handleSearchCreators = async () => {
    setIsLoadingSearchCreators(true);
    try {
      const baseUrl = process.env.REACT_APP_HOOBE_API_URL;
      const response = await fetch(`${baseUrl}/campaigns-add-media-search`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          workspaceId,
          campaignId,
          listeningKeywords,
        }),
      });
      if (response.ok) {
        toast({
          title: "Search Completed!",
          description: "Creators have been searched based on your keywords.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      } else {
        const errorData = await response.json();
        toast({
          title: "Search Failed",
          description: errorData.message || "An unexpected error occurred.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "An Error Occurred.",
        description: "Unable to search at this time. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    } finally {
      setIsLoadingSearchCreators(false);
    }
  };

  const getMediaCounts = () =>
    mediaItems.reduce((acc, { type }) => {
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});

  useEffect(() => {
    if (isOpen) {
      const campaignRef = doc(
        db,
        "workspaces",
        workspaceId,
        "campaigns",
        campaignId
      );
      const unsubscribe = onSnapshot(
        campaignRef,
        (docSnap) => {
          if (docSnap.exists()) {
            setListeningKeywords(docSnap.data().listeningKeywords || []);
          }
        },
        console.error
      );
      return unsubscribe;
    } else {
      setInputText("");
      setMediaItems([]);
      setIsLoadingAddMedia(false);
      setIsLoadingSearchCreators(false);
      setNewKeyword("");
    }
  }, [isOpen, workspaceId, campaignId]);

  const handleAddKeyword = async () => {
    const trimmed = newKeyword.trim();
    if (trimmed && !listeningKeywords.includes(trimmed)) {
      const updated = [...listeningKeywords, trimmed];
      setListeningKeywords(updated);
      setNewKeyword("");
      try {
        const campaignRef = doc(
          db,
          "workspaces",
          workspaceId,
          "campaigns",
          campaignId
        );
        await updateDoc(campaignRef, { listeningKeywords: updated });
      } catch (error) {
        console.error(error);
        toast({
          title: "Error Adding Keyword",
          description:
            "There was a problem adding your keyword. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      }
    }
  };

  const handleRemoveKeyword = async (index) => {
    const updated = listeningKeywords.filter((_, i) => i !== index);
    setListeningKeywords(updated);
    try {
      const campaignRef = doc(
        db,
        "workspaces",
        workspaceId,
        "campaigns",
        campaignId
      );
      await updateDoc(campaignRef, { listeningKeywords: updated });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error Removing Keyword",
        description:
          "There was a problem removing your keyword. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  const mediaCounts = getMediaCounts();

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay>
        <DrawerContent maxW="600px">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading size="lg">Add Media</Heading>
          </DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Heading size="md">Add Media Content</Heading>
              <Textarea
                value={inputText}
                onChange={handleInputChange}
                placeholder="Add Instagram or TikTok media links..."
                size="md"
                height="200px"
              />
              <Tooltip
                label="Add media from the input above"
                placement="top"
                fontSize="sm"
                aria-label="Add Media Tooltip"
              >
                <Button
                  colorScheme="blue"
                  onClick={handleAddMedia}
                  width="100%"
                  isDisabled={isLoadingAddMedia || mediaItems.length === 0}
                  leftIcon={
                    isLoadingAddMedia ? <Spinner size="sm" mr={2} /> : <AddIcon />
                  }
                >
                  {isLoadingAddMedia ? "Adding Media..." : "Add Media"}
                </Button>
              </Tooltip>
              {mediaItems.length > 0 && (
                <Box>
                  <Heading size="md" mb={2}>
                    Found Media
                  </Heading>
                  <VStack align="stretch" spacing={3}>
                    {Object.keys(MEDIA_TYPES).map((key) => {
                      const type = MEDIA_TYPES[key];
                      const count = mediaCounts[type];
                      return count ? (
                        <HStack key={type}>
                          <Image
                            src={MEDIA_ICONS[type]}
                            alt={type}
                            boxSize="24px"
                          />
                          <Text>
                            {type} <strong>{count}</strong>
                          </Text>
                        </HStack>
                      ) : null;
                    })}
                  </VStack>
                </Box>
              )}
            </VStack>
          </DrawerBody>
          <Flex
            p={4}
            borderTop="1px solid #e2e8f0"
            bg="white"
            direction="column"
            align="center"
          >
            {/* Listening Keywords Section */}
            <Box width="100%">
              <Heading size="md" mb={2}>
                Search With Keywords
              </Heading>
              <HStack mt={2}>
                <Input
                  value={newKeyword}
                  onChange={(e) => setNewKeyword(e.target.value)}
                  placeholder="Add new keyword"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddKeyword();
                    }
                  }}
                />
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="blue"
                  onClick={handleAddKeyword}
                >
                  Add Tag
                </Button>
              </HStack>
              <Wrap spacing={2} mt={2} h="20px">
                {listeningKeywords.map((keyword, index) => (
                  <Tag
                    key={index}
                    size="md"
                    borderRadius="full"
                    borderWidth="1px"
                  >
                    <TagLabel>{keyword}</TagLabel>
                    <TagCloseButton
                      onClick={() => handleRemoveKeyword(index)}
                    />
                  </Tag>
                ))}
              </Wrap>
            </Box>

            {/* Search Creators Button */}
            <Button
              colorScheme="blue"
              onClick={handleSearchCreators}
              width="100%"
              mt={4}
              isDisabled={
                isLoadingSearchCreators || listeningKeywords.length === 0
              }
              // isDisabled
              leftIcon={
                isLoadingSearchCreators ? <Spinner size="sm" mr={2} /> : null
              }
            >
              {isLoadingSearchCreators
                ? "Searching Creators..."
                : "Search Creators For Content"}
            </Button>
          </Flex>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default CampaignsAddMedia;
