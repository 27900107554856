// CampaignsOverview.jsx
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useOutletContext } from "react-router-dom";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox,
  IconButton,
  useDisclosure,
  Text,
  HStack,
  VStack,
  Input,
  Icon,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  TriangleUpIcon,
  TriangleDownIcon,
  AddIcon,
} from "@chakra-ui/icons";
import {
  getFirestore,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import CampaignsOverviewStats from "./CampaignsOverviewStats";
import Creator from "../../../formatting/Creator";
import InstagramFollowers from "../../../formatting/InstagramFollowers";
import TikTokFollowers from "../../../formatting/TikTokFollowers";
import CampaignsDelete from "./CampaignsDelete";
import Deliverables from "../../../formatting/Deliverables";
import Notes from "../../../formatting/Notes";
import Offer from "../../../formatting/Offer";
import Status from "../../../formatting/Status";
import { FaStar, FaRegStar } from "react-icons/fa";
import Column from "./Column";
import { FormatNumber } from "../../../formatting/FormatNumber";
import CampaignBulkButton from "./CampaignBulkButton";

const CampaignsOverview = () => {
  const { workspaceId, campaignId } = useOutletContext();
  const [campaignData, setCampaignData] = useState(null);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const deleteDisclosure = useDisclosure();
  const bulkDisclosure = useDisclosure();
  const [searchQuery, setSearchQuery] = useState("");

  // State for visible columns
  const [visibleColumns, setVisibleColumns] = useState({
    Creator: true,
    "Insta Followers": true,
    "TikTok Followers": false,
    "Insta Engagement Rate": false,
    "Insta Reels Impressions": false,
    "Insta Story Impressions": false,
    "Insta Post Impressions": false,
    "TikTok Post Impressions": false,
    Deliverables: true,
    "Exp Impressions": true,
    "Exp Rate": true,
    Offer: true,
    Notes: true,
    Favorite: true,
    Status: true,
  });

  // State for sorting
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  useEffect(() => {
    if (campaignId && workspaceId) {
      const db = getFirestore();
      const campaignRef = doc(
        db,
        "workspaces",
        workspaceId,
        "campaigns",
        campaignId
      );

      const unsubscribe = onSnapshot(
        campaignRef,
        (campaignDoc) => {
          if (campaignDoc.exists()) {
            setCampaignData(campaignDoc.data());
          } else {
            console.log("No such campaign document!");
            setCampaignData(null);
          }
        },
        (error) => {
          console.error("Error fetching campaign data:", error);
          setCampaignData(null);
        }
      );
      return () => unsubscribe();
    }
  }, [campaignId, workspaceId]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = campaignData.creators.map(
        (creator) => creator.creatorId
      );
      setSelectedCreators(allIds);
    } else {
      setSelectedCreators([]);
    }
  };

  const handleSelectRow = (e, creatorId) => {
    if (e.target.checked) {
      setSelectedCreators((prev) => [...prev, creatorId]);
    } else {
      setSelectedCreators((prev) =>
        prev.filter((id) => id !== creatorId)
      );
    }
  };

  const handleDelete = () => {
    deleteDisclosure.onOpen();
  };

  // Reset selected creators after successful deletion
  const handleDeletionSuccess = () => {
    setSelectedCreators([]);
  };

  // Handle bulk deliverables
  const handleBulkDeliverables = () => {
    bulkDisclosure.onOpen();
  };

  // Handle deliverables change for multiple creators
  const handleBulkDeliverablesChange = async (updatedDeliverables) => {
    if (!campaignData || selectedCreators.length === 0) return;

    const updatedCreators = campaignData.creators.map((creator) => {
      if (selectedCreators.includes(creator.creatorId)) {
        return {
          ...creator,
          deliverables: updatedDeliverables,
        };
      }
      return creator;
    });

    // Update the campaign document in Firestore
    const db = getFirestore();
    const campaignRef = doc(
      db,
      "workspaces",
      workspaceId,
      "campaigns",
      campaignId
    );

    // Update the creators array
    try {
      await updateDoc(campaignRef, { creators: updatedCreators });
      console.log("Deliverables updated successfully");
      bulkDisclosure.onClose();
    } catch (error) {
      console.error("Error updating deliverables:", error);
    }
  };

  const isAllSelected =
    campaignData &&
    campaignData.creators.length > 0 &&
    selectedCreators.length === campaignData.creators.length;

  // Filtering logic based on search query
  const filteredCreators = useMemo(() => {
    if (!campaignData) return [];
    return campaignData.creators.filter((creator) => {
      const query = searchQuery.toLowerCase();
      return (
        (creator.name && creator.name.toLowerCase().includes(query)) ||
        (creator.instagram_username &&
          creator.instagram_username.toLowerCase().includes(query)) ||
        (creator.tiktok_username &&
          creator.tiktok_username.toLowerCase().includes(query))
      );
    });
  }, [campaignData, searchQuery]);

  // Handle deliverables change
  const handleDeliverablesChange = (creatorId, updatedDeliverables) => {
    if (!campaignData) return;

    // Update the deliverables for the specified creator in the campaignData
    const updatedCreators = campaignData.creators.map((creator) => {
      if (creator.creatorId === creatorId) {
        return {
          ...creator,
          deliverables: updatedDeliverables,
        };
      }
      return creator;
    });

    // Update the campaign document in Firestore
    const db = getFirestore();
    const campaignRef = doc(
      db,
      "workspaces",
      workspaceId,
      "campaigns",
      campaignId
    );

    // Update the creators array
    updateDoc(campaignRef, { creators: updatedCreators })
      .then(() => {
        console.log("Deliverables updated successfully");
      })
      .catch((error) => {
        console.error("Error updating deliverables:", error);
      });
  };

  // Handle notes change
  const handleNotesChange = (creatorId, updatedNotes) => {
    if (!campaignData) return;

    // Update the notes for the specified creator in the campaignData
    const updatedCreators = campaignData.creators.map((creator) => {
      if (creator.creatorId === creatorId) {
        return {
          ...creator,
          notes: updatedNotes,
        };
      }
      return creator;
    });

    // Update the campaign document in Firestore
    const db = getFirestore();
    const campaignRef = doc(
      db,
      "workspaces",
      workspaceId,
      "campaigns",
      campaignId
    );

    // Update the creators array
    updateDoc(campaignRef, { creators: updatedCreators })
      .then(() => {
        console.log("Notes updated successfully");
      })
      .catch((error) => {
        console.error("Error updating notes:", error);
      });
  };

  // Handle offer change
  const handleOfferChange = (creatorId, updatedOffer) => {
    if (!campaignData) return;

    // Update the offer for the specified creator in the campaignData
    const updatedCreators = campaignData.creators.map((creator) => {
      if (creator.creatorId === creatorId) {
        return {
          ...creator,
          offer: updatedOffer,
        };
      }
      return creator;
    });

    // Update the campaign document in Firestore
    const db = getFirestore();
    const campaignRef = doc(
      db,
      "workspaces",
      workspaceId,
      "campaigns",
      campaignId
    );

    // Update the creators array
    updateDoc(campaignRef, { creators: updatedCreators })
      .then(() => {
        console.log("Offer updated successfully");
      })
      .catch((error) => {
        console.error("Error updating offer:", error);
      });
  };

  // Handle status change
  const handleStatusChange = (creatorId, updatedStatus) => {
    if (!campaignData) return;

    // Update the status for the specified creator in the campaignData
    const updatedCreators = campaignData.creators.map((creator) => {
      if (creator.creatorId === creatorId) {
        return {
          ...creator,
          status: updatedStatus,
        };
      }
      return creator;
    });

    // Update the campaign document in Firestore
    const db = getFirestore();
    const campaignRef = doc(
      db,
      "workspaces",
      workspaceId,
      "campaigns",
      campaignId
    );

    // Update the creators array
    updateDoc(campaignRef, { creators: updatedCreators })
      .then(() => {
        console.log("Status updated successfully");
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  // Toggle favorite status
  const toggleFavoriteStatus = (creatorId) => {
    if (!campaignData) return;

    // Update the isFavorite status for the specified creator
    const updatedCreators = campaignData.creators.map((creator) => {
      if (creator.creatorId === creatorId) {
        return {
          ...creator,
          isFavorite: !creator.isFavorite,
        };
      }
      return creator;
    });

    // Update the campaign document in Firestore
    const db = getFirestore();
    const campaignRef = doc(
      db,
      "workspaces",
      workspaceId,
      "campaigns",
      campaignId
    );

    // Update the creators array
    updateDoc(campaignRef, { creators: updatedCreators })
      .then(() => {
        console.log("Favorite status updated successfully");
      })
      .catch((error) => {
        console.error("Error updating favorite status:", error);
      });
  };

  // Function to calculate expected impressions
  const calculateExpectedImpressions = useCallback((creator) => {
    if (!creator.deliverables) return 0;

    let totalImpressions = 0;

    const impressionsMapping = {
      "Instagram Story": creator.instagram_projected_story_views || 0,
      "Instagram Post": creator.instagram_projected_post_views || 0,
      "Instagram Reel": creator.instagram_projected_reel_views || 0,
      "TikTok Video": creator.tiktok_projected_video_views || 0,
      // Add more mappings if necessary
    };

    creator.deliverables.forEach((deliverable) => {
      const { type, count } = deliverable;
      const projectedImpressionsPerType = impressionsMapping[type] || 0;
      totalImpressions += count * projectedImpressionsPerType;
    });

    return totalImpressions;
  }, []);

  // Function to calculate expected rate
  const calculateExpectedRate = useCallback(
    (creator) => {
      const expectedImpressions = calculateExpectedImpressions(creator);
      const cpm = Number(campaignData?.cpm) || 0;

      if (expectedImpressions > 0 && cpm > 0) {
        const expectedRate = (expectedImpressions / 1000) * cpm;
        return expectedRate;
      } else {
        return 0;
      }
    },
    [calculateExpectedImpressions, campaignData?.cpm]
  );

  // Function to get sort icons
  const getSortIcon = (key) => (
    <Box
      display="inline-block"
      ml={1}
      position="relative"
      width="10px"
      height="20px"
    >
      <Icon
        as={TriangleUpIcon}
        color={
          sortConfig.key === key && sortConfig.direction === "ascending"
            ? "gray.800"
            : "gray.300"
        }
        boxSize={3}
        position="absolute"
        top="0px"
        left="0"
      />
      <Icon
        as={TriangleDownIcon}
        color={
          sortConfig.key === key && sortConfig.direction === "descending"
            ? "gray.800"
            : "gray.300"
        }
        boxSize={3}
        position="absolute"
        top="10px"
        left="0"
      />
    </Box>
  );

  // Handle sort when column header is clicked
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Sorting logic
  const sortedCreators = useMemo(() => {
    if (!filteredCreators) return [];
    const sorted = [...filteredCreators];
    if (sortConfig.key !== null) {
      sorted.sort((a, b) => {
        let aValue, bValue;
        switch (sortConfig.key) {
          case "creator":
            // For Creator, sort by name or username
            aValue =
              a.name?.toLowerCase() ||
              a.instagram_username?.toLowerCase() ||
              "";
            bValue =
              b.name?.toLowerCase() ||
              b.instagram_username?.toLowerCase() ||
              "";
            break;
          case "instagram_followers":
            // For Instagram Followers
            aValue = a.instagram_followers_count || 0;
            bValue = b.instagram_followers_count || 0;
            break;
          case "tiktok_followers":
            aValue = a.tiktok_followers_count || 0;
            bValue = b.tiktok_followers_count || 0;
            break;
          case "instagram_engagement_rate":
            aValue = a.instagram_engagement_rate || 0;
            bValue = b.instagram_engagement_rate || 0;
            break;
          case "instagram_reels_impressions":
            aValue = a.instagram_projected_reel_views || 0;
            bValue = b.instagram_projected_reel_views || 0;
            break;
          case "instagram_story_impressions":
            aValue = a.instagram_projected_story_views || 0;
            bValue = b.instagram_projected_story_views || 0;
            break;
          case "instagram_post_impressions":
            aValue = a.instagram_projected_post_views || 0;
            bValue = b.instagram_projected_post_views || 0;
            break;
          case "tiktok_post_impressions":
            aValue = a.tiktok_projected_video_views || 0;
            bValue = b.tiktok_projected_video_views || 0;
            break;
          case "deliverables":
            aValue = a.deliverables ? a.deliverables.length : 0;
            bValue = b.deliverables ? b.deliverables.length : 0;
            break;
          case "expected_impressions":
            aValue = calculateExpectedImpressions(a);
            bValue = calculateExpectedImpressions(b);
            break;
          case "expected_rate":
            aValue = calculateExpectedRate(a);
            bValue = calculateExpectedRate(b);
            break;
          case "offer":
            aValue = parseFloat(a.offer) || 0;
            bValue = parseFloat(b.offer) || 0;
            break;
          case "notes":
            aValue = a.notes ? 1 : 0;
            bValue = b.notes ? 1 : 0;
            break;
          case "favorite":
            aValue = a.isFavorite ? 1 : 0;
            bValue = b.isFavorite ? 1 : 0;
            break;
          case "status":
            const statusOrder = [
              "None",
              "Emailed",
              "Confirmed",
              "Completed",
              "Rejected",
            ];
            aValue =
              statusOrder.indexOf(a.status) !== -1
                ? statusOrder.indexOf(a.status)
                : 0;
            bValue =
              statusOrder.indexOf(b.status) !== -1
                ? statusOrder.indexOf(b.status)
                : 0;
            break;
          default:
            aValue = 0;
            bValue = 0;
            break;
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [
    filteredCreators,
    sortConfig,
    calculateExpectedImpressions,
    calculateExpectedRate,
  ]);

  return (
    <Box p={4}>
      <VStack align="stretch">
        <CampaignsOverviewStats
          campaignData={campaignData}
          workspaceId={workspaceId}
          campaignId={campaignId}
        />

        {campaignData && campaignData.creators && (
          <>
            <HStack position="relative" py={2}>
              {selectedCreators.length > 0 && (
                <>
                  <IconButton
                    aria-label="Delete selected creators"
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    onClick={handleDelete}
                    isDisabled={false}
                    zIndex="1"
                    mr={2}
                  />
                  <IconButton
                    aria-label="Add deliverables to selected creators"
                    icon={<AddIcon />}
                    colorScheme="blue"
                    onClick={handleBulkDeliverables}
                    isDisabled={false}
                    zIndex="1"
                    mr={2}
                  />
                </>
              )}
              <Input
                placeholder="Search creators..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                width="100%"
              />
              <Column
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
              />
            </HStack>

            {/* Table */}
            <TableContainer
              height="calc(100vh - 272px)"
              overflowY="scroll"
              css={{
                "&::-webkit-scrollbar": { display: "none" },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
              overflowX="auto"
            >
              <Table variant="simple" size="sm">
                <Thead position="sticky" top={0} bg="white" zIndex={1}>
                  <Tr>
                    <Th px={2} width="40px">
                      <Checkbox
                        isChecked={isAllSelected}
                        isIndeterminate={
                          selectedCreators.length > 0 && !isAllSelected
                        }
                        onChange={handleSelectAll}
                        size="lg"
                      />
                    </Th>
                    {visibleColumns["Creator"] && (
                      <Th
                        onClick={() => handleSort("creator")}
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>Creator</Text>
                          {getSortIcon("creator")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["Insta Followers"] && (
                      <Th
                        onClick={() => handleSort("instagram_followers")}
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>Instagram Followers</Text>
                          {getSortIcon("instagram_followers")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["TikTok Followers"] && (
                      <Th
                        onClick={() => handleSort("tiktok_followers")}
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>TikTok Followers</Text>
                          {getSortIcon("tiktok_followers")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["Insta Engagement Rate"] && (
                      <Th
                        onClick={() =>
                          handleSort("instagram_engagement_rate")
                        }
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>Insta Eng. Rate</Text>
                          {getSortIcon("instagram_engagement_rate")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["Insta Reels Impressions"] && (
                      <Th
                        onClick={() =>
                          handleSort("instagram_reels_impressions")
                        }
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>
                            Insta Reels <br /> Impressions
                          </Text>
                          {getSortIcon("instagram_reels_impressions")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["Insta Story Impressions"] && (
                      <Th
                        onClick={() =>
                          handleSort("instagram_story_impressions")
                        }
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>
                            Insta Story <br /> Impressions
                          </Text>
                          {getSortIcon("instagram_story_impressions")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["Insta Post Impressions"] && (
                      <Th
                        onClick={() =>
                          handleSort("instagram_post_impressions")
                        }
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>
                            Insta Post <br /> Impressions
                          </Text>
                          {getSortIcon("instagram_post_impressions")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["TikTok Post Impressions"] && (
                      <Th
                        onClick={() =>
                          handleSort("tiktok_post_impressions")
                        }
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>
                            TikTok Post <br /> Impressions
                          </Text>
                          {getSortIcon("tiktok_post_impressions")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["Deliverables"] && (
                      <Th
                        onClick={() => handleSort("deliverables")}
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>Deliverables</Text>
                          {getSortIcon("deliverables")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["Exp Impressions"] && (
                      <Th
                        onClick={() => handleSort("expected_impressions")}
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>Exp. Impressions</Text>
                          {getSortIcon("expected_impressions")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["Exp Rate"] && (
                      <Th
                        onClick={() => handleSort("expected_rate")}
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>Exp. Rate</Text>
                          {getSortIcon("expected_rate")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["Offer"] && (
                      <Th
                        onClick={() => handleSort("offer")}
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>Offer</Text>
                          {getSortIcon("offer")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["Notes"] && (
                      <Th
                        onClick={() => handleSort("notes")}
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>Notes</Text>
                          {getSortIcon("notes")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["Favorite"] && (
                      <Th
                        onClick={() => handleSort("favorite")}
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>Favorite</Text>
                          {getSortIcon("favorite")}
                        </HStack>
                      </Th>
                    )}
                    {visibleColumns["Status"] && (
                      <Th
                        onClick={() => handleSort("status")}
                        cursor="pointer"
                      >
                        <HStack>
                          <Text>Status</Text>
                          {getSortIcon("status")}
                        </HStack>
                      </Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {sortedCreators.length > 0 ? (
                    sortedCreators.map((creator) => (
                      <Tr key={creator.creatorId}>
                        <Td px={2} width="40px">
                          <Checkbox
                            size="lg"
                            isChecked={selectedCreators.includes(
                              creator.creatorId
                            )}
                            onChange={(e) =>
                              handleSelectRow(e, creator.creatorId)
                            }
                          />
                        </Td>
                        {visibleColumns["Creator"] && (
                          <Td>
                            <Creator
                              profilePicUrl={
                                creator.instagram_profile_pic_url
                              }
                              username={creator.instagram_username}
                              name={creator.name}
                              isVerified={creator.instagram_is_verified}
                              hoobeHandle={creator.hoobe_handle}
                            />
                          </Td>
                        )}
                        {visibleColumns["Insta Followers"] && (
                          <Td>
                            <InstagramFollowers
                              followersCount={
                                creator.instagram_followers_count
                              }
                              username={creator.instagram_username}
                            />
                          </Td>
                        )}
                        {visibleColumns["TikTok Followers"] && (
                          <Td>
                            <TikTokFollowers
                              followersCount={creator.tiktok_followers_count}
                              username={creator.tiktok_username}
                            />
                          </Td>
                        )}
                        {visibleColumns["Insta Engagement Rate"] && (
                          <Td>
                            {creator.instagram_engagement_rate != null
                              ? `${(
                                  creator.instagram_engagement_rate * 100
                                ).toFixed(2)}%`
                              : "N/A"}
                          </Td>
                        )}
                        {visibleColumns["Insta Reels Impressions"] && (
                          <Td>
                            {FormatNumber(
                              creator.instagram_projected_reel_views
                            )}
                          </Td>
                        )}
                        {visibleColumns["Insta Story Impressions"] && (
                          <Td>
                            {FormatNumber(
                              creator.instagram_projected_story_views
                            )}
                          </Td>
                        )}
                        {visibleColumns["Insta Post Impressions"] && (
                          <Td>
                            {FormatNumber(
                              creator.instagram_projected_post_views
                            )}
                          </Td>
                        )}
                        {visibleColumns["TikTok Post Impressions"] && (
                          <Td>
                            {FormatNumber(
                              creator.tiktok_projected_video_views
                            )}
                          </Td>
                        )}
                        {visibleColumns["Deliverables"] && (
                          <Td>
                            <Deliverables
                              initialDeliverables={creator.deliverables}
                              onChange={(updatedDeliverables) =>
                                handleDeliverablesChange(
                                  creator.creatorId,
                                  updatedDeliverables
                                )
                              }
                            />
                          </Td>
                        )}
                        {visibleColumns["Exp Impressions"] && (
                          <Td>
                            {FormatNumber(
                              calculateExpectedImpressions(creator)
                            )}
                          </Td>
                        )}
                        {visibleColumns["Exp Rate"] && (
                          <Td>
                            {(() => {
                              const expectedRate =
                                calculateExpectedRate(creator);
                              return expectedRate > 0
                                ? expectedRate.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                : "$0.00";
                            })()}
                          </Td>
                        )}
                        {visibleColumns["Offer"] && (
                          <Td>
                            <Offer
                              initialOffer={creator.offer}
                              onChange={(updatedOffer) =>
                                handleOfferChange(
                                  creator.creatorId,
                                  updatedOffer
                                )
                              }
                            />
                          </Td>
                        )}
                        {visibleColumns["Notes"] && (
                          <Td>
                            <Notes
                              initialNotes={creator.notes}
                              onChange={(updatedNotes) =>
                                handleNotesChange(
                                  creator.creatorId,
                                  updatedNotes
                                )
                              }
                            />
                          </Td>
                        )}
                        {visibleColumns["Favorite"] && (
                          <Td>
                            <IconButton
                              aria-label="Toggle Favorite"
                              icon={
                                creator.isFavorite ? (
                                  <FaStar color="#fcba03" />
                                ) : (
                                  <FaRegStar />
                                )
                              }
                              variant="ghost"
                              onClick={() =>
                                toggleFavoriteStatus(creator.creatorId)
                              }
                            />
                          </Td>
                        )}
                        {visibleColumns["Status"] && (
                          <Td>
                            <Status
                              initialStatus={creator.status}
                              onChange={(updatedStatus) =>
                                handleStatusChange(
                                  creator.creatorId,
                                  updatedStatus
                                )
                              }
                            />
                          </Td>
                        )}
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="16">
                        <Text textAlign="center" color="gray.500">
                          No creators match your search.
                        </Text>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <CampaignsDelete
              isOpen={deleteDisclosure.isOpen}
              onClose={deleteDisclosure.onClose}
              selectedCreators={selectedCreators}
              workspaceId={workspaceId}
              campaignId={campaignId}
              onDeletionSuccess={handleDeletionSuccess}
            />
            <CampaignBulkButton
              isOpen={bulkDisclosure.isOpen}
              onClose={bulkDisclosure.onClose}
              onBulkDeliverablesChange={handleBulkDeliverablesChange}
            />
          </>
        )}
      </VStack>
    </Box>
  );
};

export default CampaignsOverview;
