import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useParams,
  Link as RouterLink,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Flex,
  Spinner,
  Alert,
  AlertIcon,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Image,
  useToast,
  HStack,
  Wrap,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { doc, onSnapshot, updateDoc, deleteDoc } from "firebase/firestore";
import { db, storage } from "../../../Firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  TbLayoutGrid,
  TbChartBar,
  TbMail,
  TbSettings,
  TbShare,
  TbPlus,
  TbEdit,
  TbClipboardText,
  TbDownload,
} from "react-icons/tb";
import { AddIcon } from "@chakra-ui/icons";

import CampaignsAddCreator from "./CampaignsAddCreator";
import CampaignsAddMedia from "./CampaignsAddMedia";
import CampaignsEditApplication from "./CampaignsEditApplication";
import CampaignsShareModal from "./CampaignsShareModal";

const CampaignsDetail = () => {
  const { campaignId } = useParams();
  const { workspaceData } = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const [campaign, setCampaign] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState("");
  const inputRef = useRef(null);

  const {
    isOpen: isAddCreatorOpen,
    onOpen: onAddCreatorOpen,
    onClose: onAddCreatorClose,
  } = useDisclosure();

  const {
    isOpen: isAddMediaOpen,
    onOpen: onAddMediaOpen,
    onClose: onAddMediaClose,
  } = useDisclosure();

  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();

  const {
    isOpen: isEditApplicationOpen,
    onOpen: onEditApplicationOpen,
    onClose: onEditApplicationClose,
  } = useDisclosure();

  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteConfirmOpen,
    onOpen: onDeleteConfirmOpen,
    onClose: onDeleteConfirmClose,
  } = useDisclosure();

  const [settingsName, setSettingsName] = useState("");
  const [settingsDescription, setSettingsDescription] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [settingsError, setSettingsError] = useState(null);

  const [listeningKeywords, setListeningKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState("");

  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);

  useEffect(() => {
    if (!workspaceData || !campaignId) {
      setError("Workspace data or Campaign ID is missing.");
      setIsLoading(false);
      return;
    }

    const campaignDocRef = doc(
      db,
      "workspaces",
      workspaceData.workspace_id,
      "campaigns",
      campaignId
    );

    const unsubscribe = onSnapshot(
      campaignDocRef,
      (campaignSnapshot) => {
        if (campaignSnapshot.exists()) {
          const campaignData = {
            id: campaignSnapshot.id,
            ...campaignSnapshot.data(),
          };
          setCampaign(campaignData);
          setName(campaignData.name || "");
          setSettingsName(campaignData.name || "");
          setSettingsDescription(campaignData.description || "");
          setListeningKeywords(campaignData.listeningKeywords || []);
          setError(null);
        } else {
          setError("Campaign not found.");
          setCampaign(null);
        }
        setIsLoading(false);
      },
      (err) => {
        console.error("Error fetching campaign:", err);
        setError("Failed to fetch campaign data.");
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [workspaceData, campaignId]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const saveName = useCallback(async () => {
    if (campaign && name !== campaign.name) {
      try {
        const campaignDocRef = doc(
          db,
          "workspaces",
          workspaceData.workspace_id,
          "campaigns",
          campaignId
        );
        await updateDoc(campaignDocRef, { name });
      } catch (err) {
        console.error("Error updating name:", err);
      }
    }
    setIsEditing(false);
  }, [campaign, name, workspaceData?.workspace_id, campaignId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        saveName();
      }
    };

    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, saveName]);

  const navigateTo = (path) => {
    navigate(`/dashboard/campaigns/${campaignId}${path}`);
  };

  const renderConditionalButton = () => {
    if (location.pathname.endsWith("/overview")) {
      return (
        <Button
          borderWidth="1px"
          size="sm"
          bg="white"
          leftIcon={<TbPlus />}
          onClick={onAddCreatorOpen}
          mr={2}
        >
          Add Creators
        </Button>
      );
    } else if (location.pathname.endsWith("/tracking")) {
      return (
        <Button
          borderWidth="1px"
          size="sm"
          bg="white"
          leftIcon={<TbPlus />}
          onClick={onAddMediaOpen}
          mr={2}
        >
          Add Media
        </Button>
      );
    } else if (location.pathname.endsWith("/applications")) {
      return (
        <Button
          borderWidth="1px"
          size="sm"
          bg="white"
          leftIcon={<TbEdit />}
          onClick={onEditApplicationOpen}
          mr={2}
        >
          Edit Application
        </Button>
      );
    }
    return null;
  };

  const handleSaveSettings = async () => {
    const campaignDocRef = doc(
      db,
      "workspaces",
      workspaceData.workspace_id,
      "campaigns",
      campaignId
    );

    const updates = {
      name: settingsName,
      description: settingsDescription,
      listeningKeywords,
    };

    if (selectedImageFile) {
      setUploadingImage(true);

      try {
        const imageExtension = selectedImageFile.name.split(".").pop();
        const randomNumber = Math.floor(Math.random() * 1000000);
        const imagePath = `workspaces/${workspaceData.workspace_id}/campaigns/${randomNumber}.${imageExtension}`;

        const storageRef = ref(storage, imagePath);
        await uploadBytes(storageRef, selectedImageFile);

        const imageURL = await getDownloadURL(storageRef);

        updates.imageURL = imageURL;
      } catch (error) {
        console.error("Error uploading image:", error);
        setSettingsError("Failed to upload image. Please try again.");
        setUploadingImage(false);
        return;
      }
    }

    try {
      await updateDoc(campaignDocRef, updates);
      onSettingsClose();
      setSelectedImageFile(null);
      setSettingsError(null);
    } catch (error) {
      console.error("Error updating campaign:", error);
      setSettingsError("Failed to update campaign. Please try again.");
    } finally {
      setUploadingImage(false);
    }
  };

  const handleAddKeyword = () => {
    const trimmed = newKeyword.trim();
    if (trimmed && !listeningKeywords.includes(trimmed)) {
      const updated = [...listeningKeywords, trimmed];
      setListeningKeywords(updated);
      setNewKeyword("");
    }
  };

  const handleRemoveKeyword = (index) => {
    const updated = listeningKeywords.filter((_, i) => i !== index);
    setListeningKeywords(updated);
  };

  const generateCsvContent = () => {
    if (!campaign || !campaign.creators) {
      return "";
    }

    const csvHeaders = [
      "Creator ID",
      "Name",
      "Instagram Username",
      "Instagram Followers",
      "TikTok Username",
      "TikTok Followers",
      "Instagram Engagement Rate",
      "Instagram Reels Impressions",
      "Instagram Story Impressions",
      "Instagram Post Impressions",
      "TikTok Post Impressions",
      "Deliverables",
      "Expected Impressions",
      "Expected Rate",
      "Offer",
      "Notes",
      "Favorite",
      "Status",
    ];

    const cpm = Number(campaign.cpm) || 0;

    const rows = campaign.creators.map((creator) => {
      const deliverables = creator.deliverables || [];
      let totalImpressions = 0;

      const impressionsMapping = {
        "Instagram Story": creator.instagram_projected_story_views || 0,
        "Instagram Post": creator.instagram_projected_post_views || 0,
        "Instagram Reel": creator.instagram_projected_reel_views || 0,
        "TikTok Video": creator.tiktok_projected_video_views || 0,
      };

      deliverables.forEach((deliverable) => {
        const { type, count } = deliverable;
        const projectedImpressionsPerType = impressionsMapping[type] || 0;
        totalImpressions += count * projectedImpressionsPerType;
      });

      const expectedRate = cpm > 0 ? (totalImpressions / 1000) * cpm : 0;

      return [
        creator.creatorId || "",
        creator.name || "",
        creator.instagram_username || "",
        creator.instagram_followers_count || "",
        creator.tiktok_username || "",
        creator.tiktok_followers_count || "",
        creator.instagram_engagement_rate
          ? (creator.instagram_engagement_rate * 100).toFixed(2) + "%"
          : "",
        creator.instagram_projected_reel_views || "",
        creator.instagram_projected_story_views || "",
        creator.instagram_projected_post_views || "",
        creator.tiktok_projected_video_views || "",
        deliverables.map((d) => `${d.count} x ${d.type}`).join("; "),
        totalImpressions || "",
        expectedRate.toFixed(2) || "",
        creator.offer || "",
        creator.notes || "",
        creator.isFavorite ? "Yes" : "No",
        creator.status || "",
      ];
    });

    const csvContent = [
      csvHeaders.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    return csvContent;
  };

  const handleExportCsv = () => {
    setIsGeneratingCsv(true);

    try {
      const csvContent = generateCsvContent();

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const filename = `${campaign?.name || "campaign"}_data.csv`;

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

      toast({
        title: "Export Successful",
        description: "The campaign data has been exported as a CSV file.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error exporting CSV:", error);
      toast({
        title: "Export Failed",
        description: "An error occurred while exporting the CSV file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsGeneratingCsv(false);
    }
  };

  const handleDeleteCampaign = async () => {
    try {
      const campaignDocRef = doc(
        db,
        "workspaces",
        workspaceData.workspace_id,
        "campaigns",
        campaignId
      );
      await deleteDoc(campaignDocRef);
      onDeleteConfirmClose();
      onSettingsClose();
      toast({
        title: "Campaign Deleted",
        description: "The campaign has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/dashboard/campaigns");
    } catch (error) {
      console.error("Error deleting campaign:", error);
      toast({
        title: "Delete Failed",
        description: "An error occurred while deleting the campaign.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="200px">
        <Spinner size="lg" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Box p={4}>
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Flex height="60px" alignItems="center" borderBottomWidth="2px" p={4}>
        <Flex alignItems="center" flex="1">
          <Heading size="md" as={RouterLink} to="/dashboard/campaigns" mr={2}>
            Campaigns
          </Heading>
          <Heading size="md">/</Heading>
          <Heading size="md" ml={2}>
            {campaign?.name ? (
              isEditing ? (
                <Input
                  ref={inputRef}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={saveName}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      saveName();
                    }
                  }}
                  size="md"
                />
              ) : (
                <Text
                  as="span"
                  onClick={() => setIsEditing(true)}
                  cursor="pointer"
                >
                  {campaign.name}
                </Text>
              )
            ) : (
              <Text as="span" color="gray.500">
                none
              </Text>
            )}
          </Heading>
        </Flex>

        <Flex justifyContent="center" flex="1">
          <Button
            borderWidth="1px"
            size="sm"
            bg="white"
            leftIcon={<TbClipboardText />}
            onClick={() => navigateTo("/applications")}
            mx={1}
          >
            Applications
          </Button>
          <Button
            borderWidth="1px"
            size="sm"
            bg="white"
            leftIcon={<TbLayoutGrid />}
            onClick={() => navigateTo("/overview")}
            mx={1}
          >
            Overview
          </Button>
          <Button
            borderWidth="1px"
            size="sm"
            bg="white"
            leftIcon={<TbMail />}
            onClick={() => navigateTo(`/messages`)}
            mx={1}
          >
            Messages
          </Button>
          <Button
            borderWidth="1px"
            size="sm"
            bg="white"
            leftIcon={<TbChartBar />}
            onClick={() => navigateTo("/tracking")}
            mx={1}
          >
            Tracking
          </Button>
        </Flex>
        <Flex justifyContent="flex-end" flex="1">
          {renderConditionalButton()}
          <Button
            borderWidth="1px"
            size="sm"
            bg="white"
            leftIcon={<TbSettings />}
            onClick={onSettingsOpen}
            mr={2}
          >
            Settings
          </Button>
          <Button
            borderWidth="1px"
            size="sm"
            bg="white"
            leftIcon={<TbShare />}
            onClick={onShareOpen}
          >
            Share
          </Button>
        </Flex>
      </Flex>

      <Box>
        <Outlet
          context={{
            workspaceId: workspaceData?.workspace_id,
            campaignId,
            messagesDocId: campaign.messagesDocId,
            workspaceData: workspaceData,
          }}
        />
      </Box>

      {/* Settings Modal */}
      <Modal isOpen={isSettingsOpen} onClose={onSettingsClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Campaign Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {settingsError && (
              <Alert status="error" borderRadius="md" mb={4}>
                <AlertIcon />
                {settingsError}
              </Alert>
            )}
            <VStack spacing={4} align="stretch">
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  value={settingsName}
                  onChange={(e) => setSettingsName(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  value={settingsDescription}
                  onChange={(e) => setSettingsDescription(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Image</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      setSelectedImageFile(e.target.files[0]);
                    }
                  }}
                />
              </FormControl>
              {selectedImageFile && (
                <Box>
                  <Image
                    src={URL.createObjectURL(selectedImageFile)}
                    alt="Selected Image"
                    boxSize="50px"
                    objectFit="cover"
                  />
                </Box>
              )}
              {campaign?.imageURL && !selectedImageFile && (
                <Box>
                  <Image
                    src={campaign.imageURL}
                    alt="Campaign Image"
                    boxSize="50px"
                    objectFit="cover"
                  />
                </Box>
              )}

              {/* Listening Keywords Section */}
              <Box>
                <FormLabel>Listening Keywords</FormLabel>
                <HStack mt={2}>
                  <Input
                    value={newKeyword}
                    onChange={(e) => setNewKeyword(e.target.value)}
                    placeholder="Add new keyword"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleAddKeyword();
                      }
                    }}
                  />
                  <Button
                    leftIcon={<AddIcon />}
                    colorScheme="blue"
                    onClick={handleAddKeyword}
                    w="200px"
                  >
                    Add Tag
                  </Button>
                </HStack>
                <Wrap spacing={2} mt={2}>
                  {listeningKeywords.map((keyword, index) => (
                    <Tag key={index} size="md" borderRadius="full">
                      <TagLabel>{keyword}</TagLabel>
                      <TagCloseButton
                        onClick={() => handleRemoveKeyword(index)}
                      />
                    </Tag>
                  ))}
                </Wrap>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              borderWidth="1px"
              mr={3}
              leftIcon={<TbDownload />}
              onClick={handleExportCsv}
              isLoading={isGeneratingCsv}
              disabled={isGeneratingCsv}
              spinnerPlacement="end"
            >
              {isGeneratingCsv ? "Exporting..." : "Export Campaign"}
            </Button>
            <Button colorScheme="red" onClick={onDeleteConfirmOpen} mr={3}>
              Delete
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSaveSettings}
              isLoading={uploadingImage}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteConfirmOpen} onClose={onDeleteConfirmClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this campaign? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onDeleteConfirmClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDeleteCampaign} ml={3}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <CampaignsAddCreator
        isOpen={isAddCreatorOpen}
        onClose={onAddCreatorClose}
        workspaceId={workspaceData?.workspace_id}
        campaignId={campaignId}
      />

      <CampaignsAddMedia
        isOpen={isAddMediaOpen}
        onClose={onAddMediaClose}
        workspaceId={workspaceData?.workspace_id}
        campaignId={campaignId}
      />

      <CampaignsEditApplication
        isOpen={isEditApplicationOpen}
        onClose={onEditApplicationClose}
        workspaceId={workspaceData?.workspace_id}
        campaignId={campaignId}
      />

      <CampaignsShareModal
        isOpen={isShareOpen}
        onClose={onShareClose}
        workspaceId={workspaceData?.workspace_id}
        campaignId={campaignId}
      />
    </Box>
  );
};

export default CampaignsDetail;
