import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Text,
  Image,
  Flex,
  useToast,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  VStack,
  Button,
  Badge,
  useDisclosure,
} from "@chakra-ui/react";
import { ExternalLinkIcon, ChatIcon } from "@chakra-ui/icons";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../Firebase";
import Status from "../../../formatting/Status";
import Deliverables from "../../../formatting/Deliverables";
import CampaignMessagesSendModal from "./CampaignMessagesSendModal"; // Adjust the import path as needed

const CampaignMessageCreator = ({
  creator,
  campaignId,
  workspaceId,
  creatorId,
  onUpdateCreator,
}) => {
  const [status, setStatus] = useState("None");
  const [offer, setOffer] = useState(0);
  const [deliverables, setDeliverables] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [isEditingOffer, setIsEditingOffer] = useState(false);
  const toast = useToast();
  const offerInputRef = useRef(null);
  const prevCreatorIdRef = useRef(null);

  // Initialize useDisclosure for modal control
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (creator && creator.creatorId !== prevCreatorIdRef.current) {
      prevCreatorIdRef.current = creator.creatorId;
      setStatus(creator.status || "None");
      setOffer(creator.offer !== undefined ? Number(creator.offer) : 0);
      setDeliverables(creator.deliverables || []);
      setTimeline(creator.timeline || []);
    }
  }, [creator]);

  useEffect(() => {
    if (isEditingOffer && offerInputRef.current) {
      offerInputRef.current.focus();
    }
  }, [isEditingOffer]);

  const updateCreatorData = useCallback(
    async (updatedFields) => {
      if (!creator || !campaignId || !workspaceId) return false;
      try {
        const campaignRef = doc(
          db,
          "workspaces",
          workspaceId,
          "campaigns",
          campaignId
        );

        const campaignSnapshot = await getDoc(campaignRef);
        if (campaignSnapshot.exists()) {
          const campaignData = campaignSnapshot.data();

          const creatorIndex = campaignData.creators.findIndex(
            (c) => c.creatorId === creator.creatorId
          );

          if (creatorIndex !== -1) {
            const updatedCreator = {
              ...campaignData.creators[creatorIndex],
              ...updatedFields,
            };

            // Remove undefined fields
            Object.keys(updatedCreator).forEach((key) => {
              if (updatedCreator[key] === undefined) {
                delete updatedCreator[key];
              }
            });

            const updatedCreators = [...campaignData.creators];
            updatedCreators[creatorIndex] = updatedCreator;

            await updateDoc(campaignRef, { creators: updatedCreators });

            if (onUpdateCreator) {
              onUpdateCreator(updatedCreator);
            }
            return true;
          } else {
            toast({
              title: "Error",
              description: "Creator not found in campaign data.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        } else {
          toast({
            title: "Error",
            description: "Campaign not found.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error updating data:", error);
        toast({
          title: "Error",
          description: "An error occurred while updating data.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      return false;
    },
    [creator, campaignId, workspaceId, onUpdateCreator, toast]
  );

  const handleStatusChange = async (newStatus) => {
    setStatus(newStatus);
    const updateSuccess = await updateCreatorData({ status: newStatus });
    if (!updateSuccess) {
      // Revert status back if update failed
      setStatus(creator.status || "None");
    }
  };

  const handleOfferChange = (valueNumber) => {
    setOffer(valueNumber);
  };

  const handleOfferBlur = async () => {
    setIsEditingOffer(false);
    const updateSuccess = await updateCreatorData({ offer });
    if (!updateSuccess) {
      // Revert offer back if update failed
      setOffer(creator.offer !== undefined ? Number(creator.offer) : 0);
    }
  };

  const handleOfferClick = () => {
    setIsEditingOffer(true);
  };

  const handleDeliverablesChange = async (newDeliverables) => {
    setDeliverables(newDeliverables);
    const updateSuccess = await updateCreatorData({
      deliverables: newDeliverables,
    });
    if (!updateSuccess) {
      // Revert deliverables back if update failed
      setDeliverables(creator.deliverables || []);
    }
  };

  const handleOfferResult = async (timelineItem, result) => {
    try {
      const updatedTimeline = timeline.map((item) =>
        item === timelineItem ? { ...item, result } : item
      );

      const newOffer = result === "accept" ? timelineItem.offerAmount : offer;
      const newDeliverables =
        result === "accept" ? timelineItem.deliverables : deliverables;
      const newStatus = result === "accept" ? "Accepted" : "Denied";

      const updateSuccess = await updateCreatorData({
        offer: newOffer,
        deliverables: newDeliverables,
        status: newStatus,
        timeline: updatedTimeline,
      });

      if (updateSuccess) {
        setOffer(newOffer);
        setDeliverables(newDeliverables);
        setStatus(newStatus);
        setTimeline(updatedTimeline);

        toast({
          title: `Offer ${result === "accept" ? "Accepted" : "Denied"}`,
          description: `The offer has been ${
            result === "accept" ? "accepted" : "denied"
          } and creator details updated.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to update creator data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(
        `Error ${result === "accept" ? "accepting" : "denying"} offer:`,
        error
      );
      toast({
        title: "Error",
        description: `An error occurred while ${
          result === "accept" ? "accepting" : "denying"
        } the offer.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!creator) {
    return <Text>Select a creator to view details.</Text>;
  }

  return (
    <Box p={4} >
      <Flex
        justifyContent="space-between"
        borderWidth="1px"
        borderRadius="md"
        align="center"
        p={4}
        flexDirection={{ base: "column", md: "row" }}
        h="100px"
      >
        {/* Creator Info */}
        <Flex w="250px">
          <Image
            borderRadius="full"
            boxSize="60px"
            src={creator.instagram_profile_pic_url}
            alt={creator.name}
            mr={2}
          />
          <Box>
            <Text fontSize="lg" fontWeight="bold">
              {creator.name}
            </Text>
            <Text fontSize="sm">@{creator.instagram_username}</Text>
          </Box>
        </Flex>

        {/* Status */}
        <Box >
          <Status initialStatus={status} onChange={handleStatusChange} />
        </Box>

        {/* Offer */}
        <Box >
          {isEditingOffer ? (
            <NumberInput
              value={offer}
              onChange={(_, valueNumber) => handleOfferChange(valueNumber)}
              min={0}
              placeholder="Enter offer"
              onBlur={handleOfferBlur}
              width="100px"
            >
              <NumberInputField ref={offerInputRef} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          ) : (
            <Text
              onClick={handleOfferClick}
              cursor="pointer"
              fontSize="2xl"
              fontWeight="bold"
            >
              ${offer}
            </Text>
          )}
        </Box>

        {/* Deliverables */}
        <Box>
          <Deliverables
            initialDeliverables={deliverables}
            onChange={handleDeliverablesChange}
          />
        </Box>

        {/* Buttons */}
        <Flex>
          {/* New Message Creator Button */}
          <Button
            leftIcon={<ChatIcon />}
            variant="solid"
            aria-label="Message"
            size="md"
            bg="white"
            borderWidth="1px"
            onClick={onOpen}
            mr={2}
          >
            Message
          </Button>

          {/* Existing See Offer Page Button */}
          <Button
            as="a"
            href={`https://offer.hoobe.me/${workspaceId}/${campaignId}/${creatorId}`}
            target="_blank"
            rel="noopener noreferrer"
            leftIcon={<ExternalLinkIcon />}
            bg="white"
            variant="solid"
            aria-label="Offer Page"
            size="md"
            borderWidth="1px"
          >
            Offer Page
          </Button>
        </Flex>
      </Flex>

      {/* Timeline */}
      <Box mt={4}>
        <Text fontSize="sm" fontWeight="bold" mb={2}>
          Timeline:
        </Text>
        {timeline.length > 0 ? (
          <VStack align="start" spacing={3}>
            {timeline.map((item, index) => (
              <Box
                key={index}
                p={3}
                borderWidth="1px"
                borderRadius="md"
                width="100%"
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>{item.message}</Text>
                  <Text fontSize="xs" color="gray.500">
                    {new Date(item.timestamp).toLocaleString()}
                  </Text>
                </Flex>
                <Text fontSize="xs" color="gray.600">
                  Type: {item.type}
                </Text>
                {item.type === "offer" &&
                  item.offerAmount &&
                  item.deliverables && (
                    <Box mt={2}>
                      <Text fontSize="sm">
                        Offer Amount: ${item.offerAmount}
                      </Text>
                      <Text fontSize="sm">
                        Deliverables: {JSON.stringify(item.deliverables)}
                      </Text>
                      {item.result ? (
                        <Badge
                          colorScheme={
                            item.result === "accept" ? "green" : "red"
                          }
                          mt={2}
                        >
                          {item.result === "accept" ? "Accepted" : "Denied"}
                        </Badge>
                      ) : (
                        <Flex mt={2}>
                          <Button
                            size="sm"
                            colorScheme="green"
                            mr={2}
                            onClick={() => handleOfferResult(item, "accept")}
                          >
                            Accept
                          </Button>
                          <Button
                            size="sm"
                            colorScheme="red"
                            onClick={() => handleOfferResult(item, "deny")}
                          >
                            Deny
                          </Button>
                        </Flex>
                      )}
                    </Box>
                  )}
              </Box>
            ))}
          </VStack>
        ) : (
          <Text>No timeline items available.</Text>
        )}
      </Box>

      {/* CampaignMessagesSendModal */}
      {creator && (
        <CampaignMessagesSendModal
          isOpen={isOpen}
          onClose={onClose}
          selectedCreators={[creator]}
          workspaceId={workspaceId}
          campaignId={campaignId}
        />
      )}
    </Box>
  );
};

export default CampaignMessageCreator;
