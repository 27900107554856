// src/components/CampaignsApplication.jsx

import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Spinner,
  Alert,
  AlertIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  VStack,
  SimpleGrid,
  Flex,
  Icon,
  Stat,
  StatLabel,
  StatNumber,
  Checkbox,
  IconButton,
  Input,
  HStack,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Link,
} from '@chakra-ui/react';
import {
  CheckCircleIcon,
  WarningIcon,
  DeleteIcon,
  ExternalLinkIcon,
} from '@chakra-ui/icons';
import { useOutletContext } from 'react-router-dom';
import { doc, onSnapshot, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../../../../Firebase';

const CampaignsApplication = () => {
  // Retrieve context from the parent Outlet
  const context = useOutletContext();
  const { campaignId } = context;

  // State variables to manage data, loading, and errors
  const [applicationData, setApplicationData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);

  // State for selected submission indices
  const [selectedSubmissions, setSelectedSubmissions] = useState([]);

  // State for search query
  const [searchQuery, setSearchQuery] = useState('');

  // Modal for delete confirmation
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!campaignId) {
      setError('Campaign ID is missing.');
      setIsLoading(false);
      return;
    }

    // Reference to the applications collection document with ID equal to campaignId
    const applicationDocRef = doc(db, 'applications', campaignId);

    // Set up real-time listener
    const unsubscribe = onSnapshot(
      applicationDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setApplicationData(docSnapshot.data());
          setError(null);
        } else {
          setError('Application document not found.');
          setApplicationData(null);
        }
        setIsLoading(false);
      },
      (err) => {
        console.error('Error fetching application document:', err);
        setError('Failed to fetch application data.');
        setIsLoading(false);
      }
    );

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [campaignId]);

  // Helper function to format timestamps
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  // Extract submissions and questions using useMemo for optimization
  const submissions = useMemo(
    () => applicationData?.application?.submissions || [],
    [applicationData]
  );
  const questions = useMemo(
    () => applicationData?.application?.questions || [],
    [applicationData]
  );

  // Extract additional data for the grid
  const applicationStatus = applicationData?.application?.status;
  const campaignEndDate = applicationData?.application?.endDate
    ? new Date(applicationData.application.endDate).toLocaleDateString()
    : 'N/A';

  // Filter submissions based on search query and include index
  const filteredSubmissions = useMemo(() => {
    if (!searchQuery.trim()) {
      return submissions.map((submission, index) => ({ submission, index }));
    }
    return submissions
      .map((submission, index) => ({ submission, index }))
      .filter(({ submission }) =>
        submission.instagram
          ?.toLowerCase()
          .includes(searchQuery.trim().toLowerCase())
      );
  }, [submissions, searchQuery]);

  // Handle selection of all submissions
  const isAllSelected = useMemo(() => {
    if (filteredSubmissions.length === 0) return false;
    return filteredSubmissions.every(({ index }) =>
      selectedSubmissions.includes(index)
    );
  }, [selectedSubmissions, filteredSubmissions]);

  // Update selected submissions when filteredSubmissions change
  useEffect(() => {
    setSelectedSubmissions((prevSelected) =>
      prevSelected.filter((index) =>
        filteredSubmissions.some((sub) => sub.index === index)
      )
    );
  }, [filteredSubmissions]);

  // Handle checkbox change for individual submissions
  const handleCheckboxChange = (index) => {
    setSelectedSubmissions((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  // Handle select all checkbox
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedSubmissions([]);
    } else {
      setSelectedSubmissions(filteredSubmissions.map(({ index }) => index));
    }
  };

  // Handle deletion of selected submissions
  const handleDelete = async () => {
    setIsDeleting(true);
    const applicationDocRef = doc(db, 'applications', campaignId);

    try {
      const docSnapshot = await getDoc(applicationDocRef);
      if (!docSnapshot.exists()) {
        throw new Error('Application document not found.');
      }

      const currentSubmissions = docSnapshot.data().application?.submissions || [];

      // Create a copy of the submissions array
      const updatedSubmissions = [...currentSubmissions];

      // Sort selected indices in descending order
      const indicesToDelete = selectedSubmissions
        .slice()
        .sort((a, b) => b - a);

      // Remove submissions at selected indices
      for (const index of indicesToDelete) {
        updatedSubmissions.splice(index, 1);
      }

      // Update the document with the new submissions array
      await updateDoc(applicationDocRef, {
        'application.submissions': updatedSubmissions,
      });

      setSelectedSubmissions([]);
      onClose();
    } catch (err) {
      console.error('Error deleting submissions:', err);
    } finally {
      setIsDeleting(false);
    }
  };

  // Handle status change of a submission
  const handleStatusChange = async (submissionIndex, newStatus) => {
    try {
      const applicationDocRef = doc(db, 'applications', campaignId);
      const docSnapshot = await getDoc(applicationDocRef);
      if (!docSnapshot.exists()) {
        throw new Error('Application document not found.');
      }
      const currentSubmissions = docSnapshot.data().application?.submissions || [];

      // Create a copy of the submissions array
      const updatedSubmissions = [...currentSubmissions];

      // Update the status of the submission at submissionIndex
      updatedSubmissions[submissionIndex] = {
        ...updatedSubmissions[submissionIndex],
        status: newStatus,
      };

      // Update the document with the new submissions array
      await updateDoc(applicationDocRef, {
        'application.submissions': updatedSubmissions,
      });
    } catch (err) {
      console.error('Error updating submission status:', err);
    }
  };

  const tableColumns = ['Submitted At', 'Instagram', ...questions, 'Actions'];

  if (isLoading) {
    return (
      <Box p={4} textAlign="center">
        <Spinner size="lg" />
      </Box>
    );
  }

  // Adjusted error handling
  if (error === 'Application document not found.') {
    return (
      <Box
        height="80vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize="xl" color="gray.500">
          Please create an application by editing at the top!
        </Text>
      </Box>
    );
  } else if (error) {
    return (
      <Box p={4}>
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <Box p={4}>
      <VStack align="stretch" spacing={4}>
        {/* Top Grid with Status, Total Submissions, and Campaign End Date */}
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
          {/* Status */}
          <Box
            p={4}
            borderWidth="1px"
            borderRadius="md"
            bg="white"
            boxShadow="sm"
          >
            <Flex align="center" justify="space-between">
              <Flex align="center">
                <Icon
                  as={applicationStatus ? CheckCircleIcon : WarningIcon}
                  color={applicationStatus ? 'green.500' : 'red.500'}
                  w={6}
                  h={6}
                  mr={2}
                />
                <Box>
                  <Stat>
                    <StatLabel>Status</StatLabel>
                    <StatNumber>
                      {applicationStatus ? 'Active' : 'Inactive'}
                    </StatNumber>
                  </Stat>
                </Box>
              </Flex>
              <Link
                href={`https://creatorpass.hoobe.me/${campaignId}`}
                isExternal
              >
                <ExternalLinkIcon w={6} h={6} />
              </Link>
            </Flex>
          </Box>

          {/* Total Submissions */}
          <Box
            p={4}
            borderWidth="1px"
            borderRadius="md"
            bg="white"
            boxShadow="sm"
          >
            <Stat>
              <StatLabel>Total Submissions</StatLabel>
              <StatNumber>{submissions.length}</StatNumber>
            </Stat>
          </Box>

          {/* Campaign End Date */}
          <Box
            p={4}
            borderWidth="1px"
            borderRadius="md"
            bg="white"
            boxShadow="sm"
          >
            <Stat>
              <StatLabel>Campaign End Date</StatLabel>
              <StatNumber>{campaignEndDate}</StatNumber>
            </Stat>
          </Box>
        </SimpleGrid>

        {/* Controls: Delete Button and Search Input */}
        <Flex justify="space-between" align="center">
          <HStack spacing={4}>
            {selectedSubmissions.length > 0 && (
              <IconButton
                aria-label="Delete selected submissions"
                icon={<DeleteIcon />}
                colorScheme="red"
                onClick={onOpen}
                mr={4}
              />
            )}
          </HStack>
          <Input
            placeholder="Search Instagram Username"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            maxW="full"
          />
        </Flex>

        <Box>
          {filteredSubmissions.length === 0 ? (
            <Text color="gray.500">
              No submissions found for this campaign.
            </Text>
          ) : (
            <Box overflowX="auto">
              <Table colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th px={2} width="40px">
                      <Checkbox
                        isChecked={isAllSelected}
                        onChange={handleSelectAllChange}
                        size="lg"
                      />
                    </Th>
                    {tableColumns.map((col, index) => (
                      <Th key={index}>{col}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredSubmissions.map(({ submission, index }) => (
                    <Tr key={index}>
                      {/* Checkbox */}
                      <Td px={2} width="40px">
                        <Checkbox
                          isChecked={selectedSubmissions.includes(index)}
                          onChange={() => handleCheckboxChange(index)}
                          size="lg"
                        />
                      </Td>
                      {/* Submitted At */}
                      <Td>{formatTimestamp(submission.submittedAt)}</Td>
                      {/* Instagram */}
                      <Td>{submission.instagram || 'N/A'}</Td>
                      {/* Dynamic Questions */}
                      {questions.map((question, qIdx) => (
                        <Td key={qIdx}>
                          {submission[question]
                            ? submission[question]
                            : 'N/A'}
                        </Td>
                      ))}
                      {/* Actions */}
                      <Td>
                        <Flex>
                          <Button
                            size="sm"
                            colorScheme={
                              submission.status === 'Accepted'
                                ? 'green'
                                : 'gray'
                            }
                            mr={2}
                            onClick={() =>
                              handleStatusChange(index, 'Accepted')
                            }
                          >
                            Accept
                          </Button>
                          <Button
                            size="sm"
                            colorScheme={
                              submission.status === 'Denied' ? 'red' : 'gray'
                            }
                            onClick={() =>
                              handleStatusChange(index, 'Denied')
                            }
                          >
                            Deny
                          </Button>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}
        </Box>
      </VStack>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Submissions</ModalHeader>
          <ModalBody>
            <Text>
              Are you sure you want to delete the selected{' '}
              {selectedSubmissions.length} submission(s)? This action cannot be
              undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              isLoading={isDeleting}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CampaignsApplication;
